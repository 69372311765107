<i18n>
{
  "en": {
    "staff_picks": "Staff Picks"
  },
  "ja": {
    "staff_picks": "サイトスタッフ一押し"
  }
}
</i18n>

<template>
<div class="container">
  <div class="section">
    <div class="heading">
      <h2>{{ $t('staff_picks') }}</h2>
    </div>
    <!--
    carousel for landscape images
    :perPageCustom: configure the number of visible slides with a particular browser width,
    formatted as [x, y] where x=browser width, and y=number of slides displayed
    -->
    <carousel v-if="thumbType === 'poster'"
      :perPageCustom="[[300, 2], [960, 3]]"
      :navigationEnabled="true"
      :paginationEnabled="false"
      :speed="1000"
      :class="`carousel-${thumbType}`">
      <slide v-for="movie in movieList" :key="movie.id">
        <router-link
          :to="`/movies/${movie.movie_date_id}/`"
          :data-movie-id="(dataAttributes[movie.movie_date_id]) ? dataAttributes[movie.movie_date_id].MovieID : null"
          :data-movie-title="(dataAttributes[movie.movie_date_id]) ? dataAttributes[movie.movie_date_id].Title : null"
          :data-actress-name="(dataAttributes[movie.movie_date_id]) ? dataAttributes[movie.movie_date_id].Actor : null">
          <img :src="`/assets/sample/${movie.movie_date_id}/str.jpg`" alt="" v-sfw>
        </router-link>
      </slide>
    </carousel>
    <!--
    carousel for portrait/squared images
    :perPageCustom: configure the number of visible slides with a particular browser width,
    formatted as [x, y] where x=browser width, and y=number of slides displayed
    -->
    <carousel v-if="thumbType === 'jacket'"
      :perPageCustom="[[300, 2], [640, 3], [800, 4]]"
      :navigationEnabled="true"
      :paginationEnabled="false"
      :speed="1000"
      :class="`carousel-${thumbType}`">
      <slide v-for="movie in movieList" :key="movie.id">
        <router-link
          :to="`/movies/${movie.movie_date_id}/`"
          :data-movie-id="(dataAttributes[movie.movie_date_id]) ? dataAttributes[movie.movie_date_id].MovieID : null"
          :data-movie-title="(dataAttributes[movie.movie_date_id]) ? dataAttributes[movie.movie_date_id].Title : null"
          :data-actress-name="(dataAttributes[movie.movie_date_id]) ? dataAttributes[movie.movie_date_id].Actor : null">
          <img :src="`/dyn/dla/images/movies/${movie.movie_date_id}/jacket/jacket${(locale !== 'ja') ? '-'.concat(locale) : ''}.jpg`" alt="" v-sfw>
        </router-link>
      </slide>
    </carousel>
  </div>
</div>
</template>

<script>
/* eslint max-len: 0 */
/* eslint no-unneeded-ternary: 0 */

import { Carousel, Slide } from 'vue-carousel';
import BifrostAPI from '@/assets/js/services/Bifrost/API';
import CustomLists from '@/assets/js/services/CustomLists';

export default {
  props: {
    thumbType: String,
    customListId: String,
    numMovies: Number,
  },
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      movieList: [],
      dataAttributes: {},
    };
  },
  async created() {
    const customListSvc = new CustomLists();
    this.movieList = await customListSvc.getList('movies', this.customListId);

    // hide section header in the home page if there are no staff picks set
    this.$emit('staffPicksEval', (this.movieList) ? true : false);

    // this block of logic below is needed to add data-attributes to the top page banners as part
    // of r3401. the catch here is that additional data-attributes are needed if the banner is for
    // a movie (instead of a campaign), and we don't have the needed info (movie title, actress)
    // in the banners JSON created by DL Admin. so we need to do the following so that we can have
    // this data - if they property(keyed by banner_id) exists, it's for a movie, and vue will add
    // the needed data-attributes
    const bfAPI = new BifrostAPI();
    this.movieList.forEach(async (poster) => {
      const movieDetail = await bfAPI.getMovieDetail(poster.movie_date_id);
      this.$set(this.dataAttributes, poster.movie_date_id, movieDetail);
    });
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style lang="scss" scoped>

/* thumbnail overlay  */
.VueCarousel-inner {
  .VueCarousel-slide {
    text-align: center;

    a {
      display: inline-block;
      position: relative;
      margin: 0 0.5rem;

      img {
        width: 100%;;
      }
    }
  }
}

/* carousel nav color overwrite */
.VueCarousel-navigation {
  .VueCarousel-navigation-button {
    color: #F50102;
  }
}

/* thumbnail max-width for each site */
// each site has its own thumbnail size but default fallback image is only one-sized
// set 'max-width' here to make sure the thumbnails in each carousel displayed in consistent ratio
.carousel-poster img {
  max-width: 384px;
}

.carousel-jacket img {
  max-width: 224px;
}
</style>

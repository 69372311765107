import store from '@/store';
import Deferred from '../../utils/Deferred';
import BifrostAPI from '../Bifrost/API';

export default class CustomLists {
  constructor() {
    this.bfAPI = new BifrostAPI();
    this.init();
  }

  init() {
    // check if this service has been previously initialized. if so, then don't do this
    if (store.getters['customLists/isInitialized']) return;

    console.log('%cInitializing customLists service', 'background: #222; color: #ff0', this);

    // prefetch custom list JSON
    this.loadJSON();
    store.dispatch('customLists/setInit', true);
  }

  async loadJSON() {
    const deferred = new Deferred();

    this.bfAPI.getCustomLists().then((response) => {
      deferred.resolve(response);
    });

    return deferred.promise;
  }

  getList(listType, listID) {
    const deferred = new Deferred();

    this.loadJSON().then((response) => {
      const listData = (Object.prototype.hasOwnProperty.call(response, listID)
      && (Object.prototype.hasOwnProperty.call(response[listID], listType)))
        ? response[listID][listType]
        : null;

      deferred.resolve(listData);
    });

    return deferred.promise;
  }
}
